import React from "react";
import CodeViewerLumiukkopeli from "../components/CodeViewers/codeViewer_lumiukkopeli";
import CodeViewerUpper from "../components/codeViewerUpper";

function Lumiukkopeli() {
    return [
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <CodeViewerUpper header="Lumiukkopeli" paragraphs={[
                    {
                        header: "",
                        text: `Ensimmäisenä vuonna HAMK:issa kolmen motivoituneen oppilaan ryhmässä tehty jouluteemainen FPS-selviytymispeli, jossa tarkoituksena on selvitä kylmässä talvimaailmassa, jossa lumiukot jahtaavat sinua.
                        Kurssin tarkoitus oli opettaa ja sisäistää olio-ohjelmoinnin hyödyt sovelluskehityksessä. Projektissa käytettiin Unityn omaa versiohallintatyökalua, joka mahdollisti samanaikaisesti projektin tekemisen.
                        
                        Projektissä piti toteuttaa 3 eri ohjelmointikäsitettä, kuten staattisten muuttujien käyttö, periytyminen ja yksityset, sekä julkiset muuttujat - ja selittää nämä käsitteet opettajalle.`
                    },
                    {
                        header: "Ohjelmat & ohjelmointikielet",
                        text: `C# (pelin toimintojen tekemiseen)
                        Adobe Photoshop (tekstuurien muokkaamiseen ja normal-mappien tekemiseen)
                        Blender (3D-objektejen luomiseen, kuten  aseet)
                        PHP & SQL, pelin ennätystaulukon tekemiseen.
                         
                        `,
                    },
                    {
                        header: "Tekijät",
                        text: `Joona Öhberg, Toni Heinonen, Samu Karesma`
                    },

                ]}
                />

                {/* <div style={{ margin: 85 }}></div>
                <p id="game">
                    <b>[Build: 2024]</b> <b style={{ color: "blue" }}><a target="_blank" href="http://91.155.60.85/lumiukkopeli:7000">
                        Peliä pääset testaamaan tästä linkistä, kokonäyttötilalla. </a></b>
                    <br />
                    Mikäli peliä ladatessa antaa virheviestin niin sivuston uudelleen lataaminen saattaa auttaa.
                    <br />
                    Jos latauspalkki ei liiku niin silloin pelin palvelin on kiinni.
                </p> */}

                <div style={{ margin: 85 }}></div>
                <iframe id="video" width="100%" height="760" src="https://www.youtube.com/embed/BODMQ6qvvyw?si=xDUx2NLOxLf4OilQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <div style={{ margin: 85 }}></div>
                <CodeViewerLumiukkopeli path="/UnityProjects/Lumiukkopeli/" />


            </div>
        </div>
    ];
}

export default Lumiukkopeli; 