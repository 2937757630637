import React from 'react';

function CodeViewerUpper({ header, paragraphs }) {
    return (
        <div>
            <h1 style={{ fontSize: 45, fontWeight: 'bold' }}> {header} </h1>
            {paragraphs.map((paragraph, index) => (
                <div key={index} style={{ marginBottom: 25 }}>
                    <h1 style={{ fontSize: 35 }}>{paragraph.header}</h1>
                    <div style={{ whiteSpace: "pre-line" }}>
                        <span dangerouslySetInnerHTML={{ __html: paragraph.text }} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default CodeViewerUpper;