import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function App() {
    const { unityContext } = useUnityContext({
        loaderUrl: "./EstateEmpireGame/Build/htdocs.loader.js",
        dataUrl: "./EstateEmpireGame/Build/htdocs.data.unityweb",
        frameworkUrl: "./EstateEmpireGame/Build/htdocs.framework.js.unityweb",
        codeUrl: "./EstateEmpireGame/Build/htdocs.wasm.unityweb",
    });

    return <Unity style={{ width: '80%', height: '720px' }} unityContext={unityContext} />;
}

export default App;