import React from "react";
// import CodeViewer from "../components/codeViewer";
import CodeViewerAsiakaspalvelupeli from "../components/CodeViewers/codeViewer_asiakaspalvelupeli";
import CodeViewerUpper from "../components/codeViewerUpper";

function Asiakaspalvelupeli() {
    return [
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <CodeViewerUpper header="Asiakaspalvelupeli" paragraphs={[
                    {
                        header: "",
                        text: `Ammattikoulu aikanani sain äidinkielen opettajilta tehtävän luoda 360-kuvankatselupeli liiketalouden ammattitutkinnon opiskelijoille. Pelin päätarkoituksena oli opettaa opiskelijoille asiakaspalvelun perusteet ja myymälän ulkoasu. Projektin keskeinen idea oi se, että opettajat voisivat seurata oppilaittensa edistymistä pelissä, joten minun piti asentaa Apache HTTP-palvelin peruskirjautumis- ja rekisteröintijärjestelmän luomista varten. Minulla oli jo tuolloin vähän PHP:stä, SQL:stä, sekä tietokannoista kokemusta, joten se ei ollut ongelma.`
                    },
                    {
                        header: "Ohjelmointikielet",
                        text: `C# (käyttöliittymään)
                        PHP & SQL (back-end)
                        HTML + CSS (WebGL >> Index.html-tiedoston muokkaamiseen)`
                    },
                    {
                        header: "Projektin kulku",
                        text: `Projektin aloituttuani minulla ei ollut juurikaan yhtään kokemusta Unitystä, paitsi muutamista Youtube-projektisarjoista, jolla pääsi käyttöliittymän kanssa alkuun ja perus script-komentojen kanssa.
                        Aloin etsiä oppaita 360-asteen kuvakatselijan tekemiseen Unitylla ja löysin tähän apua netistä. Tämä ei yksinään kuitenkaan riittänyt vaan minun piti keksiä tapa pelaajalle "liikkua kaupassa", joka tässä tapauksessa tapahtui vaihtamalla pallon 360-kuvatekstuuria, kuten myös muita toiminnallisuuksia, mukaan lukien pelaajan edistymisen seuraamiseen vaadittavat toimivan kirjautumisen, sekä rekisteröitymisen.
                        
                        Pelaajien tiedon tallentaminen tapahtui HTTP POST-kutsuilla, jossa lähetetään dataa parametreina palvelimelle. Onnistuin viikon sisään rakentamaan toimivan kirjautumis- ja rekisteröintijärjestelmän sha-256-salausalgorithmia käyttäen salasanojen peittämistä varten, ennen tietokantaan tallentamista. Menin jopa niin pitkälle ja ostin oman verkkotunnuksen (asiakaspalvelupeli.com), vaikka en edes saanut rahaa tästä projektista. (domain ei ole enään minun omistuksessa) Domainen tehtävä oli ohjata kävijät palvelinkoneelleni, jossa Asiakaspalvelupeli pyöri XAMPP-palvelinohjelmiston, sekä portinohjausten avulla. Viimein onnistuttuani saamaan pelin perusmekaniikat toimimaan, eli kaupassa liikkumisen, niin oli aika rakentaa tehtäviä. (Lisää niistä voit katsoa videolta sivuston alalaidassa)`
                    },
                    {
                        header: "Lopputulos",
                        text: `Kuukaudet kuluivat ja peli oli valmis julkaistavaksi. Sinä aamuna (en muista tarkkaa päivämäärää) lähetin sähköpostia opettajilleni ja kerroin heille, että peli on valmis. Sain siitä hyvää palautetta, mutta kun suuri päivä koitti ja yli 20 oppilasta yritti ladata peliä samaan aikaan, pieni kannettavan tietokoneeni, jossa oli halpa kaksiytiminen prosessori, ei pysynyt perässä ja palvelin kaatui. Tämä kaatuminen johtui peliin luodusta chat-systeemistä, joka pommitti palvelinta sekunnin väliajoin. Tämä oli todella huono idea tehdä PHP & SQL-avulla, jos voisin mennä ajassa taaksepäin niin olisin luonut tämän toiminnon Websocket-yhteydellä tai jättänyt kokonaan tekemättä. 
                        
                        Olin todella pettynyt tästä, mutta sainpahan arvokkaan opetuksen. Opettajat pitivät edelleen pelistä ja olin iloinen pelin lopputuloksesta. Tämä projekti oli iso syy, miksi aloin pitämään ohjelmoinnista entistä enemmän. Oli hauskaa luoda interaktiivisia järjestelmiä, joissa käyttäjät voivat kommunikoida pelipalvelimen kanssa ja saada heti vastauksen omien toimintojen- ja tietojen perusteella.`
                    }
                ]}
                />

                <div style={{ margin: 85 }}></div>

                <iframe id="video" width="100%" height="760" src="https://www.youtube.com/embed/57zajszoa1I?si=q32uQzMW1Rqekjr6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div style={{ margin: 85 }}></div>
                <CodeViewerAsiakaspalvelupeli path="../UnityProjects/Asiakaspalvelupeli/" />


            </div>
        </div>
    ];
}

export default Asiakaspalvelupeli; 