import React, { useState, useRef, useEffect } from 'react';
import "../App.css";

const nav = [
  { href: "/", text: "Kotisivu" },
  {
    text: (
      <span>
        Projektit
        {' '} <span style={{ fontSize: '10px' }}>▼</span>
      </span>
    ),
    submenu: [
      {
        href: "/pages/estate_empire",
        text: (
          <span>
            Estate Empire <br /><span style={{ fontSize: '12px' }}> Monopoly, like online game </span>
          </span>
        )
      },
      {
        href: "/pages/lumiukkopeli",
        text: (
          <span>
            Lumiukkopeli <br /><span style={{ fontSize: '12px' }}>  </span>
          </span>
        )
      },
      {
        href: "/pages/asiakaspalvelupeli",
        text: (
          <span>
            Asiakaspalvelupeli <br /><span style={{ fontSize: '12px' }}>  </span>
          </span>
        )
      },
      {
        href: "/pages/foxhead",
        text: (
          <span>
            Foxhead <br /><span style={{ fontSize: '12px' }}>  </span>
          </span>
        )
      },
    ]
  },
];

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
        setActiveSubmenu(null); // Close active submenu when menu is closed
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleSubMenuClick = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const handleMouseLeaveNav = () => {
    setActiveSubmenu(null);
  };

  return (
    <nav onMouseLeave={handleMouseLeaveNav} className="bg-gray-800 z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-8 w-auto" src={require('../images/logo.png')} alt="logo" />
            </div>
            <div className="ml-2">
              <div className="text-white text-xl font-bold">Joona Öhberg</div>
              <div className="text-gray-300 text-xs">Just Another Developer</div>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="block md:hidden">
            <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="text-gray-300 hover:text-white focus:outline-none">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>

          {/* Mobile Dropdown Menu */}
          {isMobileMenuOpen && (
            <div ref={menuRef} className="absolute md:hidden z-10 top-16 left-0 right-0 bg-gray-800">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {nav.map((link, index) => (
                  <div key={index}>
                    {link.submenu ? (
                      <div className="relative">
                        <a
                          href="#"
                          className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                          onClick={() => handleSubMenuClick(index)}
                        >
                          {link.text}
                        </a>
                        {activeSubmenu === index && (
                          <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg">
                            <div className="py-1">
                              {link.submenu.map((submenuItem, subIndex) => (
                                <a
                                  key={subIndex}
                                  href={submenuItem.href}
                                  className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                                >
                                  {submenuItem.text}
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <a
                        href={link.href}
                        className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                      >
                        {link.text}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Navigation Links (Desktop) */}
          <div className="hidden md:block z-10">
            <div className="ml-4 flex items-center md:ml-6">
              {nav.map((link, index) => (
                <div key={index}>
                  {link.submenu ? (
                    <div className="relative">
                      <a
                        href="#"
                        className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        onClick={() => handleSubMenuClick(index)}
                      >
                        {link.text}
                      </a>
                      {activeSubmenu === index && (
                        <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg">
                          <div className="py-1">
                            {link.submenu.map((submenuItem, subIndex) => (
                              <a
                                key={subIndex}
                                href={submenuItem.href}
                                className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white"
                              >
                                {submenuItem.text}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <a
                      href={link.href}
                      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {link.text}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>



  );
}

export default Navbar;