import React, { useState, useEffect, useRef } from 'react';
import './codeViewer.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs, atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Import a theme, e.g., Visual Studio theme

function CodeViewer({ path }) {
    const [code, setCode] = useState("");
    const [fileStructure, setFileStructure] = useState({});
    const [fileList, setFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null); // State to track selected file
    const [allFilesAndFolders, setAllFilesAndFolders] = useState([]);

    useEffect(() => {
        // I KNOW THIS IS STUPID; BUT YOU HAVE TO LEARN HARD WAY! SOME REASON YOU CANT USE Non static variables inside path...
        const allFilesAndFolders = require.context("../../../public/UnityProjects/Lumiukkopeli/", true);
        const keys = allFilesAndFolders.keys();

        // Function to build the folder structure
        function buildFolderStructure(keys) {
            const folderStructure = {};
            keys.forEach(key => {
                if (key.endsWith('.meta')) return;  //Filter out '.meta'

                const parts = key.split('/');

                // Skip the root directory and any other directory with only one part and named "."
                if (parts.length === 1 && parts[0] === ".") return;

                let currentFolder = folderStructure;

                parts.forEach((part, index) => {
                    if (index === parts.length - 1) {
                        currentFolder[part] = true; // Indicate it's a file
                    } else {
                        if (!currentFolder[part]) {
                            currentFolder[part] = {}; // Create folder if it doesn't exist
                        }
                        currentFolder = currentFolder[part];
                    }
                });
            });

            return folderStructure;
        }

        // const folderStructure = buildFolderStructure(keys);
        const folderStructure = buildFolderStructure(keys.map(key => key.substring(2))); // remove the "./" from the beginning
        setFileStructure(folderStructure);
    }, []);

    // Function to fetch code from a file
    const fetchCode = (filePath) => {
        fetch(filePath)
            .then(response => {
                if (!response.ok) { throw new Error('Network response was not ok'); }
                return response.text();
            })
            .then(text => setCode(text))
            .catch(error => console.error('Error fetching code:', error));
    };

    // Function to render file structure recursively
    function renderFileStructure(structure, depth = 0, currentPath = '') {
        const files = [];
        const subDirectories = [];
        const marginLeftValue = 8;

        Object.entries(structure).forEach(([name, content]) => {
            const fullPath = currentPath ? `${currentPath}/${name}` : name;
            const isFile = typeof content === 'boolean';
            const isSelected = fullPath === selectedFile; // Check if current file is selected

            if (isFile) {
                const listItemClasses = isSelected ? 'selected-file file-list-item' : 'file-list-item'; // Add selected-file class if selected
                const two_classes = `$(listItemClasses) file-list-item`;

                files.push(
                    <li key={fullPath} className={two_classes} style={{ marginLeft: depth * marginLeftValue }} onClick={() => {
                        setSelectedFile(fullPath); // Set selected file
                        if (currentPath) {
                            fetchCode(`${path}${currentPath}/${name}`);
                        } else {
                            fetchCode(`${path}${name}`);
                        }
                    }}>
                        <span className={{ fontWeight: isSelected ? 'bold-text' : 'normal' }}>{name}</span>
                    </li>
                );
            } else {
                subDirectories.push(
                    <li key={fullPath} className='folder' style={{ marginLeft: depth * marginLeftValue }}>
                        <i><span className="custom-span">{name}</span></i>
                        <ul>{renderFileStructure(content, depth + 1, fullPath)}</ul>
                    </li>
                );
            }
        });

        return [...files, ...subDirectories];
    }


    return (
        <>
            <h1 className="custom-heading"><b> Projektin '{path.split('/')[path.split('/').length - 2]}' (2019) lähdekoodi </b></h1>
            <div className="custom-header">
                <h1><b>{selectedFile ? selectedFile.split('/').pop() : '<<< Valitse tiedosto >>>'}</b> </h1>
            </div>
            <div className="custom-container">
                <div className="custom-sidebar flex-2 text-white flex">
                    <div className='custom-padding'>
                        <ul className="custom-list">
                            <li className='custom-span'>Root</li>
                            {renderFileStructure(fileStructure)}
                        </ul>
                    </div>
                </div>
                <div className="custom-content text-white">
                    <pre className="language-csharp">
                        <SyntaxHighlighter language="csharp" style={atomDark}>
                            {code}
                        </SyntaxHighlighter>
                    </pre>
                </div>
            </div>
        </>

    );
}

export default CodeViewer;