import React from 'react';

const CV = () => {
    return (
        <div className="cv-viewer">
            {/* <div style={{height: "0.05vh"}}/> */}
        <embed
          src="/CV.pdf"
          type="application/pdf"
          width="100%"
          height="1200vh" // Set the height to 100% of viewport height
        />

{/* <div style={{height: "0.05vh"}}/> */}

      </div>
      );
};

export default CV;