import React from "react";
import CodeViewerFoxhead from "../components/CodeViewers/codeViewer_foxhead";
import CodeViewerUpper from "../components/codeViewerUpper";

function Foxhead() {
    return [
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <CodeViewerUpper header="Foxhead" paragraphs={[
                    {
                        header: "",
                        text: `Tieto- ja viestintätekniikan perustutkinnon lopputyöksi tekemä  peli, vuonna 2018. Sain peliin paljon inspiraatiota suositusta AAA-pelistä nimeltä "Cuphead". Projektissa oli 3-kuukauden deadline ja onnistuin kehittelemään ihan hauskan pikkuprojektin tässä ajassa.
                        Projektin aloitettuani Unity-osaamiseni oli jo vähän korkeampi, johtuen aikaisemmasta projektistani (Asiakaspalvelupelistä). En kuitenkaan ollut aikaisemmin tehnyt pelejä vielä puhelimille ja tableteille, jolle projekti oli suunniteltu. C#-tuntemukseni ei ollut ihan sillä tasolla, että olisin osannut esimerkiksi tehdä esimerkiksi omia luokkia, joten koodi on todella simppeliä ja hiukan vaikeasti luettavaa näin (jälkeenpäin katsottuani, 2024). 
                        `
                    },
                    {
                        header: "Ohjelmat & ohjelmointikielet",
                        text: `C# (pelin toimintojen tekemiseen)
                        Adobe Photoshop (tekstuurien muokkaamiseen ja normal-mappien tekemiseen)
                        Blender (3D-objektejen luomiseen)
                        FL Studio (Ääniefektejen muokkaamiseen ja luomiseen) 
                        `,
                    },
                    {

                        header: "Lopputulos",
                        text: `Opettajat pitivät projektistani ja arvosanaksi tuli 3.
                        Projektin jatkokehittämismahdollisuudet ovat isot, mikäli aikaa ja motivaatiota riittää`,
                    }
                ]}
                />

                <div style={{ margin: 85 }}></div>
                <iframe id="video" width="100%" height="760" src="https://www.youtube.com/embed/EX1NGLhPo0c?si=gT3EMeVUDdiVyHiE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <div style={{ margin: 85 }}></div>
                <CodeViewerFoxhead path="/UnityProjects/Foxhead/" />


            </div>
        </div>
    ];
}

export default Foxhead; 