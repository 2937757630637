import React, { useEffect } from 'react';
import axios from 'axios';
import './home.css';


function Home() {

    return (
        <>
            <div className="relative min-h-screen">
                <video className="absolute w-full h-full object-cover top-0 left-0 z-[-50]" autoplay="autoPlay" loop="loop" muted="muted" playsinline="playsInLine" webkit-playsinline="webkit-playsinline" x5-playsinline="x5-playsinline" poster="" data-js="header-video">
                    <source src="./EstateEmpire_Loop_04.mp4" type="video/mp4" />
                    {/* <source src="https://static.playoverwatch.com/video/pages/home/overwatch-2/header-e104f624b9.mp4" type="video/ogg" /> */}
                </video>
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="flex flex-col items-center justify-center bg-black bg-opacity-80 p-12 rounded-lg text-white max-w-[800px]">
                        <div>
                            {/* <img className='rounded-full w-48 h-48' width="200" height="400" src="https://i.imgur.com/YSGlaJV.png" /> */}
                            <img className='rounded-full w-48 h-48' width="200" height="400" src={require("../images/__omakuva.png")} />
                        </div>

                        {/* Content for the first column */}
                        {/* <div className="">
                            <h1 className="text-4xl font-bold mb-4">Joona Öhberg</h1>
                            <ul className="text-bold ml-4">
                                <li>27-Vuotta</li>
                                <li>Valmistunut Tradenomi (Hämeen Ammattikorkeakoulusta)</li>
                                <li>Web-sovellusten ja nettisivujen tekeminen</li>
                                <li>Kokemusta IoT-laitteista, sekä Linux-käyttöjärjestelmistä</li>
                                <li>Unity 5 - peliohjelmointi harrastuksena</li>
                                <li>Kokemusta useammasta ohjelmointikielestä, sekä frameworkeista</li>
                            </ul>
                        </div> */}

                        <div className="mt-4 ">
                            <h1 className="text-4xl font-bold mb-2 text-center">Joona Öhberg</h1>

                            <div className='mt-5'></div>
                            <div className='border-b-2'></div>
                            <div className='mt-5'></div>


                            <ul className="text-bold ml-1"> {/* Apply left margin */}
                                <li className="flex items-center">28-vuotta</li>
                                <li className="flex items-center"> Valmistunut IT-Tradenomi (Hämeen Ammattikorkeakoulusta, 2023)</li>
                                <li className="flex items-center"> Unity 5-peliohjelmointi</li>
                                <li className="flex items-center"> Web-sovellukset, sekä nettisivujen tekeminen</li>
                                <li className="flex items-center">Kokemusta IoT-laitteista, sekä Linux-käyttöjärjestelmistä</li>
                            </ul>

                            <br />

                            <h1 className="text-4xl font-bold mb-2">Vahvuudet</h1>
                            <ul className="text-bold text-left ml-1"> {/* Apply left margin */}
                                <li className="flex items-center">
                                    <span className="mr-2"> <i className="fas fa-plus"></i> </span>
                                    Periksiantamattomuus
                                </li>
                                <li className="flex items-center">
                                    <span className="mr-2"> <i className="fas fa-plus"></i> </span>
                                    Positiivinen persoona
                                </li>
                                <li className="flex items-center">
                                    <span className="mr-2"> <i className="fas fa-plus"></i> </span>
                                    Avoin kritiikille
                                </li>
                                <li className="flex items-center">
                                    <span className="mr-2"> <i className="fas fa-plus"></i> </span>
                                    Oma alotteisuus
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div >


            {/* Introduction */}
            <div className='py-16 bg-gray-50'>
                <div className="flex flex-col lg:flex-row px-4 sm:px-4 md:px-8 lg:px-32" style={{ height: 'auto', paddingBottom: '17em' }}>
                    <div className="lg:px-16 md:px-2 sm:py-8 py-16">
                        {/* Your main content here */}
                        <h1 className="text-4xl font-bold mb-4">Moi!</h1>
                        <p className="text-gray-800">
                            Minun nimeni on Joona ja olen 28-vuotias vasta valmistunut Tradenomi Hämeen Ammattikorkeakoulusta (31.12.2023).
                            Rakastan kaikkea ohjelmointiin liittyvää ja olen harrastanut useamman vuoden jo pelien tekemistä Unityllä.
                            Olen koonnut merkittävimmät projektini tälle sivulle koodinäytteineen.
                        </p>
                    </div>
                    <div className="lg:px-16 md:px-2 sm:py-8 py-16">
                        {/* Your main content here */}
                        {/* <h1 className="text-4xl font-bold mb-4 ">Tietoa</h1> */}
                        <p className="text-gray-800">
                            {/* Harrastuksiini kuuluu Unity 5:lla peliohjelmointi,
                            kaikenlainen ohjelmointi, joko nettisivujen, webappejen tai discord-bottejen parissa.
                            Kunnosta myös tulee huolta pidettyä ja kerran viikossa yritän kuntosalillakin käydä. */}
                        </p>
                    </div>

                    <div className="lg:px-16 md:px-2 sm:py-8 py-16">
                        {/* Your main content here */}
                        <h1 className="text-4xl font-bold mb-4 ">Kokemus</h1>
                        <p className="text-gray-800">
                            Olen harrastuksieni kautta tehnyt monenlaista ohjelmointia. Nettisivujen front-end- ja back-end-kehityksessä olen käyttänyt useita eri frameworkeja ja kirjastoja, kuten Reactia, Bootstrapia ja Tailwindia. Olen myös tehnyt peliohjelmointia Unityllä ja Unreal Enginellä sekä 3D-mallinnusta Blenderillä. Lisäksi olen tutustunut NodeJS:ään Discord-bottien tekemisessä ja luonut verkonkaavintaohjelman, joka käy käyttäjän syöttämässä URL-osoitteessa ja taltioi kaikki sieltä löytyvät linkit ja kuvat. Ohjelma lähettää nämä tiedot Discord-kanavalle ja jatkaa etsimistä, kunnes uusia linkkejä ei enää löydy.
                        </p>
                    </div>
                </div>



                {/* Työkokemus */}
                <div className="px-2 sm:px-4 md:px-8 lg:px-48 py-8 md:py-16 bg-gray-100" style={{ height: 'auto', paddingBottom: '8.5em' }} >
                    <h1 className="text-4xl font-bold pb-8 md:pb-16">Työkokemus</h1>
                    <div className="grid gap-8 md:grid-cols-3 lg:grid-cols-3">
                        <div className="bg-white shadow-md p-4 md:p-8 rounded-lg mx-auto">
                            <h2 className="font-bold text-lg md:text-xl mb-2">Oksidia: Full Stack Developer, Osa-aikainen</h2>
                            <h3 className="font-bold text-sm mb-1">04.2022 - 09.2022</h3>
                            <h4 className="font-bold text-xs mb-2">AngularJS, Typescript, Laravel, Git, HTML & SCSS</h4>
                            <img src={require("../images/1440x800_oksidia.jpg")} alt="Oksidia" className="w-full mb-4 md:mb-6" />
                            <p className="">
                                Oppilaitoshallintajärjestelmään toimintojen tekemistä asiakkaiden toimesta. Työnkuvaan kuului AngularJS:llä ja Laravelilla front- sekä backend toimintojen tekemistä, kuten myös SCSS-tyylien kirjoittamista ja Githubin käyttämistä. Työtä tehtiin senioreitten opastamana, kuin myös itsenäisesti ja sain paljon arvokasta työkokemusta tältä ajanjaksolta.
                            </p>
                        </div>
                        <div className="bg-white shadow-md p-4 md:p-8 rounded-lg mx-auto">
                            <h2 className="font-bold text-lg md:text-xl mb-2">HAMK SMART: harjoittelu</h2>
                            <h3 className="font-bold text-sm mb-1">24.05.2021 - 31.08.2021</h3>
                            <h4 className="font-bold text-xs mb-2">Excel, Unreal Engine (Blueprints)</h4>
                            <img src={require("../images/1440x800_hamksmart.png")} alt="HAMK SMART" className="w-full mb-4 md:mb-6" />
                            <p>
                                IT-laitteiston asennusta ja kokoamista. Työhuoneen uudelleen järjestelyä.
                                Unreal Enginellä VR-projektin muuttaminen, kosketusnäyttöön sopivaksi.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Omat Projektit */}
                <div className="px-2 sm:px-4 md:px-8 lg:px-48 py-8 md:py-16 bg-gray-50">
                    <h1 className="text-4xl font-bold pb-8 md:pb-16">Omat projektit</h1>
                    <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {/* Your project containers here */}

                        <a href="./pages/estate_empire">
                            <div class="bg-white shadow-md p-4 md:p-8 rounded-lg project-container">
                                <h2 class="font-bold text-lg md:text-xl mb-2">Estate Empire | Unity 5 | Opinnäytetyö</h2>
                                <h3 class="font-bold text-sm mb-1">2022+</h3>
                                <h4 class="font-bold text-xs mb-2">C#, HTML, CSS, XAMPP, Websockets, Blender, Photoshop</h4>
                                <img src={require("../images/1440x800_estateempire.jpg")} alt="Estate Empire" class="w-full mb-4 md:mb-6" />
                                <p className="text-sm md:text-base">
                                    HAMK:issa <a class="text-custom-blue font-bold" target="_blank" href="https://www.theseus.fi/bitstream/handle/10024/817338/Ohberg_Joona.pdf?sequence=2&isAllowed=y">Opinnäytetyökseni</a> Unity 5:lla
                                    tehty selaimella toimiva lautapeli, jossa on moninpeliominaisuus käyttäen Websocketteja - suunniteltu pelattavaksi puhelimililla ja tietokoneilla portrait & landscape-tiloilla DGS-palvelimen avulla.
                                </p>
                            </div>
                        </a>
                        {/* Repeat the same structure for other project containers */}
                        <a href="./pages/lumiukkopeli">
                            <div className="bg-white shadow-md p-4 md:p-8 rounded-lg project-container">
                                <h2 className="font-bold text-lg md:text-xl mb-2">Lumiukkopeli | Unity 5 </h2>
                                <h3 className="font-bold text-sm mb-1">2019</h3>
                                <h4 className="font-bold text-xs mb-2">C#, PHP, MySQL, Blender, Photoshop</h4>
                                <img src={require("../images/1440x800_lumiukko.jpg")} alt="Lumiukkopeli" className="w-full mb-4 md:mb-6" />
                                <p className="text-sm md:text-base">
                                    Ensimmäisenä vuonna HAMKissa kolmen opiskelijan ryhmässä teimme Unity 5:lla jouluaiheisen ammuskelupelin,
                                    jossa sinun täytyy selviytyä kylmässä talvimaailmassa ja varoa pahoja lumiukkoja, jotka haluavat metsästää sinut!
                                </p>
                            </div>
                        </a>

                        <a href="./pages/foxhead">
                            <div className="bg-white shadow-md p-4 md:p-8 rounded-lg project-container">
                                <h2 className="font-bold text-lg md:text-xl mb-2">Foxhead | Unity</h2>
                                <h3 className="font-bold text-sm mb-1">2018</h3>
                                <h4 className="font-bold text-xs mb-2">C#, Blender, Photoshop</h4>
                                <img src={require("../images/1440x800_foxhead.jpg")} alt="Foxhead" className="w-full mb-4 md:mb-6" />
                                <p className="text-sm md:text-base">
                                    Tieto- ja viestintätekniikan perustutkinnon lopputyökseni tekemä 2D / -3D peli, jossa tarkoituksena on mennä kenttiä läpi ja kukistaa "pomo"-vastuksia.
                                    Projekti sai paljon inspiraatiota "Cuphead"-nimisestä AAA-pelistä pelin idealta, sekä mekaniikoiltaan.
                                </p>
                            </div>
                        </a>

                        <a href="./pages/asiakaspalvelupeli">
                            <div className="bg-white shadow-md p-4 md:p-8 rounded-lg project-container">
                                <h2 className="font-bold text-lg md:text-xl mb-2">Asiakaspalvelupeli | Unity</h2>
                                <h3 className="font-bold text-sm mb-1">2017</h3>
                                <h4 className="font-bold text-xs mb-2">C#, PHP, MySQL, XAMPP, MariaDB, HTML, CSS, JavaScript</h4>
                                <img src={require("../images/1440x800_asiakaspalvelupeli.jpg")} alt="Asiakaspalvelupeli" className="w-full mb-4 md:mb-6" />
                                <p className="text-sm md:text-base">
                                    Ammattikouluaikana luotu peli tilaustyönä opettajien toimesta ensimmäisen vuoden merkonomiopiskelijoille. Tämä oli minun ensimmäinen
                                    iso projektini kyseisellä pelimoottorilla ja peliin oli myös tehty toimiva rekisteröinti, sekä kirjautumisnäkymä pelaajien tietojen tallennusta varten.
                                </p>
                            </div>
                        </a>

                    </div>


                </div >







                {/* Hostaaminen */}
                {/* <div className="px-16 md:px-24 lg:px-48 py-8 md:py-16 bg-gray-100">
                    <h1 className="text-4xl font-bold pb-8 md:pb-16">Hostaaminen</h1>
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        <div className="bg-white shadow-md p-4 md:p-8 rounded-lg">
                            <h2 className="font-bold text-lg md:text-xl mb-2">Estate Empire | Unity 5 | Opinnäytetyö</h2>
                            <h3 className="font-bold text-sm mb-1">2022+</h3>
                            <h4 className="font-bold text-xs mb-2">C#, HTML, CSS</h4>
                            <img src={require("../images/1440x800_estateempire.jpg")} alt="Estate Empire" className="w-full mb-4 md:mb-6" />
                            <p className="text-sm md:text-base">
                                HAMK:issa <a className="text-custom-blue font-bold" target="_blank" href="https://www.theseus.fi/bitstream/handle/10024/817338/Ohberg_Joona.pdf?sequence=2&isAllowed=y">Opinnäytetyökseni</a> Unity 5:lla
                                tehty selaimella toimiva lautapeli, jossa on moninpeliominaisuus käyttäen Websocketteja.
                                Suunniteltu pelattavaksi puhelimililla ja tietokoneilla portrait & landscape-tiloilla DGS-palvelimen avulla.
                            </p>
                        </div>
                    </div>
                </div > */}



                {/* <div className='min-h-screen flex'>
                    <div className="grid grid-cols-3 gap-0">
                        {images.map((image, index) => (
                            <div key={index} className="relative">
                                <img className="object-cover w-full h-full" src={image.src} alt={image.alt} />
                                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 opacity-90">
                                    <h2 className="text-lg font-bold">{image.header}</h2>
                                    <p className="text-sm">{image.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}


                {/* <div className="grid grid-cols-3 gap-0">
                    {images.map((image, index) => (
                        <div key={index} className="relative">
                            <img className="object-cover w-full h-full" src={image.src} alt={image.alt} />
                            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 opacity-90">
                                <h2 className="text-lg font-bold">{image.header}</h2>
                                <p className="text-sm">{image.description}</p>
                            </div>
                        </div>
                    ))}
                </div> */}

            </div >
        </>
    );
}

export default Home;