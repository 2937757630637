import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';

import Navbar from "./components/navbar.js";
import Footer from "./components/footer.js";
import Home from "./pages/home.js";
import EstateEmpire from "./pages/estate_empire.js";
import Lumiukkopeli from "./pages/lumiukkopeli";
import Asiakaspalvelupeli from "./pages/asiakaspalvelupeli";
import Foxhead from "./pages/foxhead.js";
import EstateEmpireGame from "./pages/estateEmpireGame";
import CV from "./pages/cv";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pages/estate_empire" element={<EstateEmpire />} />
        <Route path="/pages/lumiukkopeli" element={<Lumiukkopeli />} />
        <Route path="/pages/foxhead" element={<Foxhead />} />
        <Route path="/pages/asiakaspalvelupeli" element={<Asiakaspalvelupeli />} />
        <Route path="/pages/estateEmpireGame" element={<EstateEmpireGame />} />
        <Route path="/cv" element={<CV />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
