import React from 'react';
import "../App.css";

function Footer() {
  return (
    <footer className="bg-gray-800 py-6 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="h-8 w-auto" src={require('../images/logo.png')} alt="Workflow" />
            </div>
            <div className="ml-2 text-gray-400">© 2024 Joona Öhberg</div>
          </div>
          <div className="flex">
            {/* <a href="#" className="text-gray-400 hover:text-white ml-4">About</a>
            <a href="#" className="text-gray-400 hover:text-white ml-4">Contact</a>
            <a href="#" className="text-gray-400 hover:text-white ml-4">Privacy Policy</a> */}
          </div>
        </div>
      </div>
    </footer >
  );
}

export default Footer;