import React from "react";
import CodeViewerEstateEmpire from "../components/CodeViewers/codeViewer_estateEmpire";
import CodeViewerUpper from "../components/codeViewerUpper";

function EstateEmpire() {
    return [
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <CodeViewerUpper header="EstateEmpire" paragraphs={[
                    {
                        header: "",
                        text: `HAMK:issa <b><a href="https://www.theseus.fi/handle/10024/817338">opinnäytetyökseni</a></b> Unity 5:lla selaimelle tehty pelattava lautapeli, jossa on toimiva moninpeli Websockttejen ja DGS-palvelimen avulla - toimii molemmilla tietokoneilla, sekä älypuhelimilla.
                       `
                    },
                    {
                        header: "Ohjelmat & Kielet",
                        text: `C#, käyttöliittymä, sekä palvelin puolelle.
                        Adobe Photoshop, tekstuurien käsittelyyn.
                        Blender, hahmojen muotoiluun, riggaukseen ja animointiin.
                        XAMPP, WebGL-buildin väliaikaiseen hostaamiseen.
                        
                        `
                    },
                    {
                        header: "Tekijä",
                        text: `Joona Öhberg 
                        Projekti on ollut kehityksessä jo vuodesta 2022 asti`
                    },
                ]}
                />

                <div style={{ margin: 85 }}></div>

                <p id="game">
                    <b>[Build: 10.03.2024]</b> <b style={{ color: "blue" }}><a target="_blank" href="http://91.155.60.85:7000/">
                        Peliä pääset testaamaan tästä linkistä, kokonäyttötilalla. </a></b>
                    <br />
                    Mikäli peliä ladatessa antaa virheviestin niin sivuston uudelleen lataaminen saattaa auttaa.
                    <br />
                    Jos latauspalkki ei liiku niin silloin pelin palvelin on kiinni.
                </p>
                {/* <iframe
                    title="External Content"
                    src="http://91.155.60.85/"
                    width="100%"
                    height="900"
                    frameBorder="0"
                /> */}

                <div style={{ margin: 85 }}></div>

                {/* <CodeViewerEstateEmpire path="/UnityProjects/EstateEmpire/" /> */}


                {/* <iframe id="video" width="100%" height="760" src="https://www.youtube.com/embed/BODMQ6qvvyw?si=xDUx2NLOxLf4OilQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

            </div>
        </div >
    ];
}

export default EstateEmpire; 